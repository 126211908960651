@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-inter;
  pointer-events: auto !important;
}

button {
  @apply font-inter;
}

.btnPrimary {
  color: white;
  background-color: #df7a5e;
  border-radius: 8px;
  width: 100%;
  padding: 12px 12px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btnPrimary:hover {
  box-shadow: 4px 4px 8px 0px #00000040;
}
.btnSecondary {
  color: white;
  background-color: #050B33;
  border-radius: 8px;
  width: 100%;
  padding: 12px 12px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnSecondary:hover {
  box-shadow: 4px 4px 8px 0px #00000040;
}
.btnSimple {
  color: #686c82;
  border: 1px solid #686c82;
  border-radius: 8px;
  width: 100%;
  padding: 11px 12px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSimple:hover {
  box-shadow: 4px 4px 8px 0px #00000040;
}

/* .centerBanner {
    background-image: url("/public/images/center-banner-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
} */

/* .ParentBanner {
    background-image: url("/public/images/parent-banner-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
} */

.ViewCenter {
  background-image: url("https://a360csastorage.blob.core.windows.net/childcare/e2717037-6870-48c5-9f92-cfc28a47f07e-fileName-profile-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/* .adventureEpisode {
    background-image: url("/public/images/Adventures_images/adventure-episode-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .aboutUsBanner {
    background-image: url("/public/images/aboutUs-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

.ccfHomeBanner {
  background-image: url("https://a360csastorage.blob.core.windows.net/childcare/ed096856-ac81-4bc5-81c9-47a0376950fb-fileName-banner-bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
}

/* .contactUsbanner {
    background-image: url("/public/images/contactUs-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .ccf-center-banner {
    background-image: url("/public/images/CCF_images/ccf-center-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .ccf-parent-banner {
    background-image: url("/public/images/CCF_images/ccf-parent-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .ccf-teacher-banner {
    background-image: url("/public/images/CCF_images/ccf-teacher-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .medEvaluationDateBanner {
    background-image: url("/public/images/MedEvaluation_images/med-evaluation-date-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .bookingSuccessful {
    background-image: url("/public/images/MedEvaluation_images/bookingSuccessBanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .consultationbanner {
    background-image: url("/public/images/consultation-banner-img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

/* .blogsbanner {
    background-image: url("/public/images/blogsBanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
} */

.swiper-pagination-bullet {
  background-color: white;
  opacity: 100;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df7a5e;
  width: 12px;
  height: 12px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipercustom-prev,
.swipercustom-next {
  position: absolute;
  top: -30px;
  right: 40px;
  transform: translateY(-50%);
  z-index: 100;
  cursor: pointer;
  left: auto;
}

.swipercustom-next {
  right: 10px;
}

.swipercustom-prev img,
.swipercustom-next img {
  width: 30px;
  height: 30px;
}

.scrollHide ::-webkit-scrollbar {
  display: none;
}

.scrollHide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollHide.sw-10{
  -ms-overflow-style: inherit !important;
  scrollbar-width: auto !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.scrollX::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollX::-webkit-scrollbar-thumb {
  background: #bebfcd;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}
/* .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
} */

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #bebfcd;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #686c82;
}

.css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

.css-1xc3v61-indicatorContainer {
  color: #686c82 !important;
}

.childRange.mt-2.css-b62m3t-container .css-1nmdiq5-menu {
  top: 100%;
  right: 0;
  position: absolute;
  width: 64%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 0px;
  margin-top: 1px;
  box-sizing: border-box;
}

.childRange.mt-2.css-b62m3t-container .css-1n6sfyn-MenuList {
  max-height: 325px;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding: 10px 6px !important;
  box-sizing: border-box;
}

.childRange.mt-2.css-b62m3t-container .css-10wo9uf-option {
  color: #686c82;
}
@layer utilities {
  .no-arrow-tooltip [data-popper-arrow] {
    display: none;
  }
}
.newTask .css-13cymwt-control {
  background-color: #f6f9fb !important;
  border: none !important;
}
.styles-module_dark__xNqje {
  background: #df7a5e !important;
  color: var(--rt-color-white);
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.forwardText {
  color: #df7a5e;
  font-style: italic;
}
table.checklistTable tr th:nth-of-type(1) {
  width: 145px;
}
table.checklistTable tr th:nth-of-type(3) {
  width: 145px;
}
table.checklistTable tr th:nth-of-type(4) {
  width: 88px;
}
table.checklistMonthlyTable tr th:nth-of-type(3) {
  width: 145px;
}
table.checklistMonthlyTable tr th:nth-of-type(2) {
  width: 145px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    cursor: ew-resize;
    border-radius: 50%;
    @apply bg-primary;
  }
}

@media (max-width: 767.98px) {
  .btnPrimary {
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .btnSimple {
    padding: 10px 10px;
    font-size: 16px;
  }
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #bebfcd;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #686c82;
}
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}


.bg-bannerGradiant {
  background: linear-gradient(
    to left,
    rgba(244, 241, 222, 0.62) 0%,
    rgba(244, 241, 222, 0.62) 56%,
    rgba(244, 241, 222, 0.62) 73%,
    rgba(244, 241, 222, 1) 100%
  );
}

.dropdown-menu {
  display: none;
}

.dropdown-menu.show {
  display: block;
}

.groupBTn:hover svg path {
  stroke: #df7a5e;
}

.slider {
  appearance: none;
  height: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.slider::-webkit-slider-thumb {
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: #3490dc;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background-color: #3490dc;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.slider:focus::-webkit-slider-thumb {
  outline: 2px solid #000;
}
.slider:focus::-moz-range-thumb {
  outline: 2px solid #000;
}

.accordion-collapse.collapse {
  display: none;
}
.accordion-collapse.collapse.show {
  display: block;
}

.acc_btn .collapsed .icon svg {
  transform: rotate(0deg);
}

.acc_btn .icon svg {
  transform: rotate(180deg);
}

.acc_btn button {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
}

.menuRight .dropDown {
  left: 0 !important;
  right: 0 !important;
  top: 100% !important;
  inset: inherit !important;
  transform: inherit !important;
  right: 0 !important;
}

.menuRight .dropDown.show {
  display: flex !important;
  align-items: flex-start;
}

.scrollWrapper {
  width: max-content;
}
.scrollWrapper .scrollHide {
  overflow-y: scroll;
}

.bg-white.react-tooltip {
  background: #fff !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 25s linear infinite;
}

.animate-marquee:hover {
  animation-play-state: paused;
}


.transform-none{
  transform:  none !important;
  inset: auto !important;
  top: 100% !important;
  right: 0 !important;
}
.custom-picker .react-datepicker{
  @apply flex
}

.custom-picker 
.react-datepicker__week{
@apply flex
}

.custom-picker 
.react-datepicker__day,
.custom-picker  .react-datepicker__month-text,
.custom-picker  .react-datepicker__quarter-text,
.custom-picker  .react-datepicker__year-text{
@apply flex-1 m-0 rounded-none mb-1
}

.custom-picker 
.react-datepicker__month-container{
@apply w-full
}

.custom-picker 
.react-datepicker__day-names{
  display: flex;
  width: 100%;
}

.custom-picker .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  @apply flex-1 rounded-none
}
.custom-picker .react-datepicker__day:not([aria-disabled=true]):hover, 
.custom-picker .react-datepicker__month-text:not([aria-disabled=true]):hover, 
.custom-picker .react-datepicker__quarter-text:not([aria-disabled=true]):hover, 
.custom-picker .react-datepicker__year-text:not([aria-disabled=true]):hover,

.custom-picker .react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover, 
.custom-picker .react-datepicker__month-text--keyboard-selected:not([aria-disabled=true]):hover,
.custom-picker .react-datepicker__quarter-text--keyboard-selected:not([aria-disabled=true]):hover,
.custom-picker .react-datepicker__year-text--keyboard-selected:not([aria-disabled=true]):hover{
  @apply rounded-none text-secondary bg-[#3396F8]
}
.custom-picker .react-datepicker__header{
  @apply bg-white border-none
}

.custom-picker .react-datepicker__day--selected,
.custom-picker .react-datepicker__day--in-selecting-range, 
.custom-picker .react-datepicker__day--in-range, 
.custom-picker .react-datepicker__month-text--selected, 
.custom-picker .react-datepicker__month-text--in-selecting-range, 
.custom-picker .react-datepicker__month-text--in-range, 
.custom-picker .react-datepicker__quarter-text--selected, 
.custom-picker .react-datepicker__quarter-text--in-selecting-range,
.custom-picker .react-datepicker__quarter-text--in-range, 
.custom-picker .react-datepicker__year-text--selected,
.custom-picker .react-datepicker__year-text--in-selecting-range, 
.custom-picker .react-datepicker__year-text--in-range{
  background-color: #D3E6F8;
  @apply text-secondary
}
.custom-picker .react-datepicker__day--range-start{
@apply rounded-tl-full rounded-bl-full bg-[#3396F8]
  }

  .custom-picker .react-datepicker__day--range-end {
    @apply rounded-tr-full rounded-br-full bg-[#3396F8]
  }


  .bottomDays .dropdownMenu{
    bottom: 100%;
    left: 0;
  }

  .custom-select__indicator {
    padding:  0 2px 0 0 !important; 
  }
  .custom-select__placeholder {
    margin: 0 !important;
    @apply line-clamp-1 font-normal text-xs
  }
  .custom-select__control{
    min-height: 28px !important;
    height: 28px !important;
  }

  .custom-select__value-container input{
    display: none !important;
  }
 .react-datepicker-popper-z-index {
  z-index: 9999 !important;
}

#datePickerPortal .react-datepicker-wrapper,
#datePickerPortal .react-datepicker__input-container {
  display: block;
  width: 100%;
}

#datePickerPortal .react-datepicker {
  width: 100%;
}